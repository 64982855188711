import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TertiaryLightButton, TertiaryDarkButton } from './Button'
import MenuBackgroundMobile from './../../assets/images/header-mobile.png'
import MenuBackground from './../../assets/images/header.png'
import LogoImage from './../../assets/images/citident-logo-large.png'
import { scrollToElement } from './../Helpers/scroller'
import VerticalNav from './VerticalNav'

import { useDispatch, useSelector } from 'react-redux'
import { setSelectedService } from './Actions/service'
import { services } from './../Helpers/serviceList'

const StyledMenuContainer = styled.div.attrs(props => ({
  className: ""
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
`

const Banner = styled.div`
  height: 136px;
  background: #ffc4c4;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.08)
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media (min-width: 992px) {
    height: 80px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

const BannerContent = styled.div`
  @media (min-width: 992px) {
    padding: 15px 0px 0px 10px;
  }
`

const BannerTitle = styled.div`
  color: ${ props => props.theme.colors.baseDark };
  font-family: "League Spartan";
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  line-height: 1.375rem;
`

const BannerText = styled.div`
  height: 19px;
  width: 296px;
  color: #4A4A4A;
  font-family: ${ props => props.theme.fonts.body };
  font-size: 13px;
  letter-spacing: 0.23px;
  line-height: 20px;
  padding: 5px 19px;
`

const Actions = styled.div`
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (min-width: 992px) {
    margin: 0px 0px;
    flex-direction: column;
  }
`

const DownloadButton = styled(TertiaryDarkButton)`
  @media (min-width: 992px) {
    margin: 0px 40px auto 0px;
  }
`

const CloseButtonLarge = styled(TertiaryLightButton).attrs( props => ({
  className: "d-block d-lg-none"
}))`
`

const CloseButtonSmall = styled.div.attrs( props => ({
  className: "d-none d-lg-block"
}))`
  margin: 0px 10px 0px auto;
`

const Menu = styled.nav.attrs( props => ({
  className: props.showMenuBackground ? "scrolled" : ""
}))`
  display: flex;
  height: 115px;
  background-color: transparent;
  padding: 20px 30px;
  transition: background-image .2s ease-in;
  
  > div {
    transition: color 0.2s ease-in;
  }

  > .logo {
    opacity: 0;
  }

  &.scrolled {
    background: url(${MenuBackgroundMobile}) no-repeat;
    background-position: top center;
    background-size: cover;
    transition: background-image .2s ease-in;

    > div {
      color: ${ props => props.theme.colors.matisse };
    }

    > .logo {
      opacity: 1;
    }

    .phone-icon {
      box-shadow: none !important
    }
  }

  @media (min-width: 992px) {
    height: 150px;

    &.scrolled {
      background: url(${MenuBackground}) no-repeat;
      background-size: cover;
      background-position: top center;
    }
  }
`

const MobileButton = styled.div.attrs( props => ({
  className: 'd-flex d-lg-none'
}))`
  font-size: 26px;
  color: ${ props => props.theme.colors.matisse };
  padding-top: 10px;
`

const StyledMobileMenu = styled.div.attrs( props => ({
  className: 'd-flex d-lg-none'
}))`
  background: #FCFCFC;
  color: ${ props => props.theme.colors.matisse };
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; 
  flex-direction: column;
  margin: -20px -193px -20px -53px;
  z-index: 10;

  .vertical-nav {
    li {
      padding: 10px 30px;
    }
  }
`

const LargeMenu = styled.ul.attrs( props => ({
  className: 'd-none d-lg-flex flex-row'
}))`
  list-style-type: none;
  text-align: right

  li {
    margin: 0px 10px;
  }
`

const LargeMenuItem = styled.li.attrs( props => ({
}))`
  margin: 20px 10px;
  color: ${ props => props.theme.colors.matisse };
  font-family: "League Spartan";
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-transform: uppercase;
  cursor: pointer;
`

const StyledServiceBox = styled.div`
  background-color: white;
  position: absolute;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 0 10px 0 #7F9AB8;

  div {
    cursor: pointer;
  }
`

const Logo = styled.div.attrs( props => ({
  className: 'logo'
}))`
  height: 51px;
  width: 137px;
  background: url(${LogoImage});
  background-size: cover;
  margin: 0px auto;

  @media (min-width: 992px) {
    margin: 0px auto 0px 0px;
  }
`

const banner = (showBanner, toggleBanner) => {
  return showBanner ? <Banner>
                 <BannerContent>
                   <BannerTitle><FontAwesomeIcon icon={'info-circle'} /> Help stop the spread of Covid-19</BannerTitle>
                   <BannerText>Get our Covid-19 guidelines here.</BannerText> 
                 </BannerContent>
                 <Actions>
                   <CloseButtonSmall onClick={ () => toggleBanner() }><FontAwesomeIcon icon={['fal', 'times']} /></CloseButtonSmall>
                   <DownloadButton onClick={ () => { window.open('/covid-information.pdf', '_blank') } }>Download Now</DownloadButton>
                   <CloseButtonLarge onClick={ () => toggleBanner() }>Close</CloseButtonLarge>
                 </Actions>
               </Banner> : null
}

const MainMenu = (props) => {
  const menu = useSelector(store => store.menu)
  const bannerData = useSelector(store => store.banner)

  const dispatch = useDispatch(); 

  const [expand, setExpand] = useState(false)
  const [expandMobile, setExpandMobile] = useState(false)

  const toggleExpand = () => {
    setExpand(!expand)
  }

  const toggleExpandMobile = () => {
    setExpandMobile(!expandMobile)
  }

  const setServiceState = (serviceName) => {
    dispatch(setSelectedService(null))
    setTimeout(() => { dispatch(setSelectedService(serviceName)) }, 250)
    toggleExpand()
  }

  const serviceList =  services.map( service => <LargeMenuItem key={service.key}>
    <div onClick={ () => setServiceState(service.title.replace(/ /g,'')) }>{service.title}</div>
  </LargeMenuItem>)

  const serviceBox = <StyledServiceBox>
    { serviceList }
  </StyledServiceBox>

  const MobileMenuHeader = styled.div`
      display: flex;
      height: 115px;
      width: 100%;
      background-color: transparent;
      padding: 20px 30px;
      background: url(${MenuBackgroundMobile}) no-repeat;
      background-position: top left;
      background-size: cover;
      
      > div {
        color: ${ props => props.theme.colors.matisse };
      }
    }
  `

  const PhoneIcon = styled.div.attrs(props => ({
    className: "phone-icon"
  }))`
    background: white;
    width: 50px;
    height: 50px;
    text-align: center;
    padding: 5px 0px;
    margin-top: -15px;
    border-radius: 50%;
    box-shadow: 0 0 10px 0 #7F9AB8;
  `

  const mobileMenu = <StyledMobileMenu>
    <MobileMenuHeader>
        <MobileButton onClick={() => toggleExpandMobile() }><FontAwesomeIcon icon={['fal', 'times']} /></MobileButton>
        <Logo />
        <MobileButton><FontAwesomeIcon icon={'phone-alt'} /></MobileButton>
    </MobileMenuHeader>
    <div className="vertical-nav">
      <VerticalNav closeMenuCallback={ () => { toggleExpandMobile() } }/>
    </div>
  </StyledMobileMenu>

  return(<StyledMenuContainer>
      { banner(bannerData.show, props.toggleBanner) }      
      <Menu showMenuBackground={ menu.showBackground }>
        <MobileButton onClick={() => toggleExpandMobile() }><FontAwesomeIcon icon={'bars'} /></MobileButton>
        { expandMobile && mobileMenu } 
        <Logo />
        <LargeMenu>
          <LargeMenuItem onClick={ () => { scrollToElement('about-us', bannerData.show)} }>About Us</LargeMenuItem>
          <LargeMenuItem>
            <div onClick={ () => toggleExpand() }>
              Services <FontAwesomeIcon icon={expand ? 'caret-down' : 'caret-right'} />
            </div>
            { expand && serviceBox }
          </LargeMenuItem>
          <LargeMenuItem onClick={ () => { scrollToElement('payment-options', bannerData.show)} }>Payment Options</LargeMenuItem>
          <LargeMenuItem onClick={ () => { scrollToElement('contact-us', bannerData.show)} }>Contact Us</LargeMenuItem>
        </LargeMenu>
        <MobileButton onClick={ () => { scrollToElement('contact-us', bannerData.show)} }><PhoneIcon><FontAwesomeIcon icon={'phone-alt'} /></PhoneIcon></MobileButton>
      </Menu>
  </StyledMenuContainer>)
}

export default MainMenu
