import React from 'react'
import  styled from 'styled-components'
import { SectionTitle, SectionSubTitle } from './Titles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Cecile from './AboutUs/Cecile'
import Edward from './AboutUs/Edward'
import Emeliza from './AboutUs/Emeliza'
import OtherStaff from './AboutUs/OtherStaff'
import ClinicCarousel from './AboutUs/ClinicCarousel'

const StyledAboutUs = styled.div.attrs(props => ({
  className: "container"
}))`
  margin-bottom: 30px;
`

const StyledCredentialsWrapper = styled.ul`
  margin-top: 35px !important;
  list-style-type: none;
  margin: 0px;
  padding: 0px;

  li {
    color: #4A4A4A;
    margin-bottom: 10px;
    font-family: Poppins;
    font-size: 0.825rem;
    line-height: 1.5rem;
  }
`

export const ReadMoreButton = styled.div.attrs(props => ({
  className: "d-block d-lg-none" 
}))`
  margin-top: 20px;
  color: ${ props => props.theme.colors.matisse };
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.35px;
  line-height: 22px;
  text-transform: uppercase;
`

export const CredentialsWrapper = (props) => {
  return(<StyledCredentialsWrapper>
    { props.children }
  </StyledCredentialsWrapper>)
}

const IconStyle = styled.span.attrs(props => ({
}))`
  color: ${ props => props.theme.colors.matisse };
  font-size: 1.2rem;
  
  svg { 
    margin-top: 5px;
  }
`

export const Credentials = (props) => {
  return<li className="row">
    <div className="col-1 text-right"><IconStyle><FontAwesomeIcon icon={ props.icon } /></IconStyle></div><div className="col-11">{ props.content }</div>
  </li>
}

export const WriteupContentFull = styled.div.attrs(props => ({
  className: "col-12" 
}))`
  color: #4A4A4A;
  font-family: Poppins;
  font-size: 0.825rem;
  line-height: 1.5rem;
`

export const WriteupContent = styled.div.attrs(props => ({
  className: "col-12 col-lg-6" 
}))`
  color: #4A4A4A;
  font-family: Poppins;
  font-size: 0.825rem;
  line-height: 1.5rem;
`

export const StaffName = styled.h5.attrs(props => ({
}))`
  color: ${ props => props.theme.colors.matisse };
  font-family: "League Spartan";
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 2rem;
  text-transform: uppercase;
`

export const StaffNameSmall = styled(StaffName)`
  font-size: 1.5rem;
  line-height: 1.5rem;
`

export const StaffTitle = styled.h5.attrs(props => ({
}))`
  color: #4A4A4A;
  font-family: "League Spartan";
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25rem;
  text-transform: uppercase;
`

export const StaffTitleSmall = styled(StaffTitle)`
  font-size: 0.825rem;
`

const AboutUs = (props) => {
  return(<StyledAboutUs name="about-us">
    <SectionTitle>About Us</SectionTitle>
    <SectionSubTitle>Our Clinic</SectionSubTitle>
    <ClinicCarousel />
    <SectionSubTitle>Our Team</SectionSubTitle>
    <Cecile />
    <Edward />
    <Emeliza />
    <OtherStaff />
  </StyledAboutUs>)
}

export default AboutUs
