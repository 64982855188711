import React, { useState, useEffect, Fragment } from 'react'
import CarouselPhoto from './CarouselPhoto'
import Slider from 'react-slick'
import  styled from 'styled-components'
import { PrevArrow, NextArrow } from './CarouselArrows'

const ClinicCarousel = (props) => {
  const [photos, setPhotos] = useState({
    data: [],
    error: false,
    loading: true,
    loaded: true,
  })

  useEffect(() => {
    setPhotos({ ...photos, data: [], error: null, loading: true, loaded: false})
    fetch('/api/photos/carousel')
      .then(async response => {
        const data = await response.json()

        // const photoCarousel = (<Fragment>
        //   { data.forEach(function(photo) { <div>{ photo.caption }</div>}) }
        // </Fragment>)

        setPhotos({
          data: data,
          error: !response.ok,
          loading: false,
          loaded: true,
        })
      })
      .catch(error => {
        //fetch throws an error only on network failure or if anything prevented the request from completing
        setPhotos({
          ...photos,
          response: { status: "network_failure" },
          error: true,
          loading: false,
        })
      })
  }, [])

  //  { photos.loaded ? (
  //    photos.data.forEach(function(photo) { <div>{ photo.caption }</div>})   
  //  ) : (
  //    <h3>Loading</h3>
  //  )}
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    className: "photos-carousel",
    dots: true,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      }
    ],
  };

  const ClinicCarouselWrapper = styled.div`
    margin-bottom: 30px;
  ` 

  const slider = <Slider {...settings}>
    { photos.data.map( photo => <CarouselPhoto key={Math.random()} caption={photo.caption} photo={photo.photo} />) }
  </Slider>

  return(<ClinicCarouselWrapper>
    { photos.loaded && slider }
  </ClinicCarouselWrapper>)
}

export default ClinicCarousel
