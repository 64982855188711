import  styled from 'styled-components'

export const SectionTitle = styled.h2`
  color: ${ props => props.theme.colors.matisse };
  font-family: "League Spartan";
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.25rem;
  text-transform: uppercase;
`

export const SectionSubTitle = styled.h3`
  color: #4A4A4A;
  font-family: "League Spartan";
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.25rem;
  text-transform: uppercase;
`