import React from 'react'
import  styled from 'styled-components'
import ServicesList from './ServicesList'
import ContactSection from './ContactSection'
import PaymentOptions from './PaymentOptions'
import Footer from './Footer'
import AboutUs from './AboutUs'
import bgHeroMobile from './../../assets/images/hero-mobile.jpg'
import bgHeroDesktop from './../../assets/images/hero-desktop.jpg'
import bgWaveDesktop from './../../assets/images/wave-desktop.png'
import logo from './../../assets/images/logo.png'
import { PrimaryButton } from './Button'
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'
import { setMenuBackground } from './Actions/menu'



const StyledLandingPage = styled.div.attrs(props => ({
  className: "container-fluid"
}))`
  color: ${ props => props.theme.colors.baseDark }
`

const HeroImage = styled.div.attrs(props => ({
  className: "row"
}))`
  min-height: 640px;
  height: 90vh;
  background-color: #FFF;
  background: url(${bgHeroMobile}) top center / cover no-repeat;
  background-size: 100%;

  @media (min-width: 992px) {
    min-height: 100vh;
    background: url(${bgHeroDesktop}) no-repeat;
    background-size: cover;
  }
`

const BlurbContainer = styled.div.attrs(props => ({
}))`
  margin-top: auto;

  @media (min-width: 992px) {
    margin-top: auto;
    margin-bottom: 0px;
    width: 100%;
  }
`
const WaveContainer = styled.div.attrs(props => ({
}))`

  @media (min-width: 992px) {
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 145px;
      background: url(${bgWaveDesktop}) center top / cover no-repeat;
      position: absolute;
      background-size: 100%;
      top: -77px; 
    }
  }
`

const ContentContainer = styled.div.attrs(props => ({
  className: "pb-5"
}))`
  .container {
    margin: auto 0px 0px 0px;

    .welcome {
      height: 18px;
      width: 98px;
      color: #3D2D17;
      font-family: "League Spartan";
      font-size: 0.75rem;
      font-weight: bold;
      letter-spacing: 0.0625rem;
      line-height: 1.125rem;
      text-transform: uppercase;
      padding-left: 10px;
    }

    img {
      width: 300px;
      margin: 10px 0px;
    }

    p {
      color: #000000;
      font-family: ${ props => props.theme.fonts.body };
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      text-shadow: 0 2px 4px 0 #FFF;
    }
  }


  @media (min-width: 992px) {
    width: 100%;
    margin-top: -1px;
    background-color: #FFF;

    .container {
      margin: auto auto 0px auto;
    }
  }
`

const LandingPage = (props) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.4,
  });

  const dispatch = useDispatch()
  dispatch(setMenuBackground(!inView))

  return(<StyledLandingPage>
      <HeroImage ref={ref}>
        <BlurbContainer>
          <WaveContainer />
          <ContentContainer>
            <div className="container">
              <div className="col-12 col-lg-6 pb-5">
                <div className="welcome">Welcome to</div>
                <img src={logo} alt="Citident Oral Care Centre" />
                <p>WE ARE OPEN! Book an appointment now with your trusted and friendly dentist in <b>Burnaby, BC.</b> We’ll give you more reasons to smile with our expert dental team, personalized care and advanced equipment.</p>
              </div>
            </div>
          </ContentContainer>
        </BlurbContainer>
      </HeroImage>
      <AboutUs />
      <ServicesList />
      <PaymentOptions />
      <ContactSection />
      <Footer />
    </StyledLandingPage>)
}

export default LandingPage
