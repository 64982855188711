import React, { Fragment, useState } from 'react'
import  styled from 'styled-components'
import { StaffName,
         StaffTitle,
         CredentialsWrapper,
         Credentials,
         ReadMoreButton,
         WriteupContent
       } from './../AboutUs'

import ManagerPhoto from './../../../assets/images/manager.jpg'

const Photo = styled.img`
  width: 100%;
  border-radius: 5px;
`

const StyledRow = styled.div.attrs(props => ({
  className: "row" 
}))`
  margin-top: 50px;
`

const Edward = (props) => {
  const [expandMobile, setExpandMobile] = useState(false)

  const toggleExpandMobile = () => {
    setExpandMobile(!expandMobile)
  }

  const writeupExpand = <span> To fully prepare for his role, he completed courses on dental office administration and payroll & accounting in Vancouver. 
    Like many Filipino-Canadians, the Pasig-born and bred Edward loves basketball and music. Ask him about it!
  </span>

  const credentials = <CredentialsWrapper>
    <Credentials icon={['fas', 'medal']} content="Graduated with a degree of Business Management from the University of the East (Manila)"/>
    <Credentials icon={['fas', 'medal']} content="Completed Vancouver Career College’s Payroll and Accounting Program with Honours"/>
    <Credentials icon={['fas', 'briefcase']} content="Certificate in Dental Office Administration"/>
    <Credentials icon={['fas', 'plane']} content="Loves travelling with the family"/>
    <Credentials icon={['fas', 'church']} content="Active in church and community activities"/>
    <Credentials icon={['fas', 'comments']} content="Fluent in English, Tagalog and Cebuano"/>
  </CredentialsWrapper>

  const employeeDetails = <Fragment>
    <StaffName>Edward Aparte</StaffName>
    <StaffTitle>Office Manager</StaffTitle>
  </Fragment>

  return(<StyledRow>
    <div className="d-block d-lg-none col-12">
      { employeeDetails }
    </div>
    <div className="col-6 col-lg-4">
      <Photo src={ ManagerPhoto } alt="Edward Aparte" />
    </div>
    <div className="col-6 col-lg-8">
      <div className="d-lg-block d-none">
        { employeeDetails }
      </div>
      <div className="d-none d-lg-block">
        <div className="row">
          <WriteupContent>
            Making sure everything runs smoothly and taking care of patients and staff, are just two of Edward’s passions for Citident Oral Care Centre.
             An experienced manager, Edward was an 11-year veteran of the Philippine banking industry before moving to Canada in 2008. 
          </WriteupContent>
          <WriteupContent>
            To fully prepare for his role, he completed courses on dental office administration and payroll & accounting in Vancouver. 
            Like many Filipino-Canadians, the Pasig-born and bred Edward loves basketball and music. Ask him about it!
          </WriteupContent>
        </div>
      </div>
      <div className="d-lg-none d-block">
        <div className="row">
          <WriteupContent>
            Making sure everything runs smoothly and taking care of patients and staff, are just two of Edward’s passions for Citident Oral Care Centre. 
            An experienced manager, Edward was an 11-year veteran of the Philippine banking industry before moving to Canada in 2008. 

            { expandMobile && writeupExpand }

            <ReadMoreButton className="d-block d-lg-none" onClick={() => toggleExpandMobile() }>Read { expandMobile ? 'Less' : 'More' }</ReadMoreButton>
          </WriteupContent>
        </div>
      </div>
      <div className="d-none d-lg-block">
        { credentials }      
      </div>
    </div>
    <div className="d-lg-none d-block col-12">
      { credentials }
    </div>
  </StyledRow>)
}

export default Edward
