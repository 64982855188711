const startingState = {
  showBackground: false
}

const menuReducer = (state = startingState, action) => {
  switch(action.type) {
    case 'SET_MENU_BACKGROUND':
      return { 
        ...state,
        showBackground: action.payload
      }
    default:
      return state
  }  
};

export default menuReducer;