import React, { Fragment } from 'react'
import  styled from 'styled-components'
import ServiceCard from './ServiceCard'
import { SectionTitle } from './Titles'
import { services } from './../Helpers/serviceList'
import BgServicesDesktop from '../../assets/images/bg-services-desktop.jpg'

const StyledServicesList = styled.div`
  margin-top: 5px;
  color: ${ props => props.theme.colors.baseDark };
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }
`

const ServicesWrapper = styled.div.attrs(props => ({
}))`
  background-color: ${ props => props.theme.colors.primaryLight };
  margin: 0px -15px;
  padding: 30px 15px;

  @media (min-width: 992px) {
    background: url(${BgServicesDesktop}) no-repeat;
    background-size: cover;
  }
`


const ServicesList = (props) => {
  return(<ServicesWrapper>
      <div className="row">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SectionTitle>Our Services</SectionTitle>
            </div>
          </div>
          <StyledServicesList>
            { services.map( service => <ServiceCard key={ service.key } title={ service.title } description={service.description}><img src={service.photo} /></ServiceCard>)}
          </StyledServicesList>
        </div>
      </div>
    </ServicesWrapper>)
}

export default ServicesList
