import React, { Fragment } from 'react'
import  styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TertiaryLightButton } from './Button'
import { SectionTitle } from './Titles'
import MapMobile from './../../assets/images/map-mobile.jpg'
import MapDesktop from './../../assets/images/map-desktop.jpg'
import CitidentEmail from './../../assets/images/citident-email.png'

const StyledContactSection = styled.div`

  @media (min-width: 992px) {
  }
`

const ContactDetails = styled.div.attrs(props => ({
  className: "m-0 p-0"
}))`
  margin: 0px 0px;
  padding: 25px 15px;
  width: 100%;
  background: ${ props => props.theme.colors.baseLight };

  @media (min-width: 992px) {
    padding-left: 20%;
  }
`

const MapBackground = styled.div`
  margin: 0px -15px;
  padding: 200px 0px 10px 0px;
  background: url(${MapMobile}) no-repeat;
  background-position: top center;
  background-size: cover;

  @media (min-width: 992px) {
    padding: 50px 0px 10px 0px;
    background: url(${MapDesktop}) no-repeat;
    background-position: bottom center;
    background-size: cover;
  }
`
const ContactWrapper = styled.div.attrs(props => ({
  className: "col-12 col-lg-6 p-0"
}))`
  margin: 70px 0px 150px 0px;
  background: ${ props => props.theme.colors.matisse };
  color: ${ props => props.theme.colors.baseLight };
  border-radius: 5px 5px 0px 0px;

  @media (min-width: 992px) {
    margin-left: auto !important;
  }
`
const ContactInfo = styled.div`
  padding: 20px;
  background: ${ props => props.theme.colors.matisse };
  color: ${ props => props.theme.colors.baseLight };
`
const ContactRow = styled.div.attrs(props => ({
  className: "mt-3"
}))`
  display: flex;
  flex-direction: row;
`

const Label = styled.div`
  margin: 0px 20px 0px 0px;
`

const Detail = styled.div`
color: ${ props => props.theme.colors.baseLight };
font-family: Poppins;
line-height: 1.5rem;

  .contact-day {
    width: 50px;
    display: inline-block;
    margin-right: 20px;
  }
`

const Map = styled.iframe`
  border: 0px;
`

const ContactSectionTitle = styled(SectionTitle)`
  background: #fff;
  padding: 20px 20px 15px 25px;
  border-radius: 5px 5px 0px 0px;
`

const goToGoogleMaps = () => {
  window.location.href="https://goo.gl/maps/545feoLG53sMeVsr6"
}

const ContactSection = (props) => {
  return<StyledContactSection name="contact-us">
        <ContactDetails>
          <MapBackground onClick={ () => { goToGoogleMaps() } }>
            <div className="container" onClick={ (e) => { e.stopPropagation() } }>
              <ContactWrapper>
                <ContactSectionTitle>Contact Us</ContactSectionTitle>
                <ContactInfo>
                  <ContactRow>
                    <Label><FontAwesomeIcon icon={'map-marked-alt'} /></Label>
                    <Detail>
                      CITIDENT ORAL CARE CENTRE<br />
                      4603 Kingsway - Unit 306<br />
                      Burnaby BC V5H 4M4<br /><br />
                      <TertiaryLightButton onClick={ () => { goToGoogleMaps() } }>Open in Maps</TertiaryLightButton>
                    </Detail>
                  </ContactRow>

                  <ContactRow>
                    <Label><FontAwesomeIcon icon={['fal', 'parking-circle']} /></Label>
                    <Detail>Please use Parking Stalls 36 and 37</Detail>
                  </ContactRow>

                  <ContactRow>
                    <Label><FontAwesomeIcon icon={'phone-alt'} /></Label>
                    <Detail><a href="tel:(604) 439-1113" style={{ color: "inherit",textDecoration: "none"}}>(604) 439-1113</a></Detail>
                  </ContactRow>

                  <ContactRow>
                    <Label><FontAwesomeIcon icon={['fas', 'phone-office']} /></Label>
                    <Detail><a href="tel:(604) 439-1190" style={{ color: "inherit",textDecoration: "none"}}>(604) 439-1190</a></Detail>
                  </ContactRow>

                  <ContactRow>
                    <Label><FontAwesomeIcon icon={'envelope'} /></Label>
                    <Detail><img src={CitidentEmail} onClick={ () => window.location = "mailto:citidentocc@gmail.com"} style={ { cursor: "pointer" } } /></Detail>
                  </ContactRow>

                  <ContactRow>
                    <Label><FontAwesomeIcon icon={'clock'} /></Label>
                    <Detail>
                      <span className="contact-day">Mon</span> 10am to 5pm<br />
                      <span className="contact-day">Tue</span> 10am to 5pm<br />
                      <span className="contact-day">Wed</span> 10am to 5pm<br />
                      <span className="contact-day">Thu</span> 10am to 4pm<br />
                      <span className="contact-day">Fri</span> 10am to 5pm<br />
                      <span className="contact-day">Sat</span> 9am to 2pm<br />
                      <br />
                      <strong>Closed on Sundays and Holidays</strong><br />
                    </Detail>
                  </ContactRow>
                </ContactInfo>
              </ContactWrapper>
            </div>
          </MapBackground>
        </ContactDetails>
      
    </StyledContactSection>
}

export default ContactSection
