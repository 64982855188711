import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import  styled, { ThemeProvider } from 'styled-components'
import theme from './../Theme' 
import MainMenu from './MainMenu'
import LandingPage from './LandingPage'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { setBannerVisibility } from './Actions/banner'

const MainContent = styled.div`
  margin-top: ${ props => props.showBanner ? "136px" : "0px" };
`

const PublicApp = () => {
  const [cookies, setCookie] = useCookies(['banner'])
  const bannerValue = cookies.banner == "true" || cookies.banner == undefined

  const dispatch = useDispatch()

  const banner = useSelector(store => store.banner)

  const toggleBanner = () => {
    setCookie('banner', !banner.show)
    dispatch(setBannerVisibility(!banner.show))
  }

  useEffect(() => {
    dispatch(setBannerVisibility(bannerValue))
  }, []);

  return(<ThemeProvider theme={theme}>
    <MainMenu
      toggleBanner={ toggleBanner } />

    <MainContent>
      <Switch>
        <Route exact path="/" component={LandingPage} />
      </Switch>
    </MainContent>
  </ThemeProvider>)
}

export default PublicApp
