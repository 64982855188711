const theme = () => {
  return {
    colors: {
      baseDark: "#000",
      baseLight: "#FFF",
      matisse: "#13789C",
      primaryLight: "#B5D0ED",
      primaryDark: "#4A4A4A"
    },
    fonts: {
      title: "'League Spartan', 'sans-serif'",
      body: "Poppins, sans-serif"
    }
  }
}

export default theme