const startingState = {
  selectedService: null
}

const serviceReducer = (state = startingState, action) => {
  switch(action.type) {
    case 'SET_SELECTED_SERVICE':
      return { 
        ...state,
        selectedService: action.payload
      }
    default:
      return state
  }  
};

export default serviceReducer;