import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import PublicApp from './../Components/PublicApp'
import { CookiesProvider } from 'react-cookie'

import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import combinedReducer from '../Components/Reducers'
import thunkMiddleware from 'redux-thunk'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faInfoCircle,
         faBars,
         faCaretRight,
         faCaretDown,
         faMapMarkedAlt,
         faPhoneAlt,
         faEnvelope,
         faClock,
         faComments,
         faChurch,
         faPlane,
         faBriefcase,
         faMedal } from '@fortawesome/free-solid-svg-icons'
import { faTimes, faParkingCircle, faChevronRight, faChevronLeft, faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons'
import { faPhoneOffice, faHandsUsd } from '@fortawesome/pro-solid-svg-icons'
 
library.add(fab,
            faInfoCircle,
            faBars,
            faCaretRight,
            faCaretDown,
            faTimes,
            faPhoneAlt,
            faEnvelope,
            faClock,
            faParkingCircle,
            faPhoneOffice,
            faMapMarkedAlt,
            faComments,
            faChurch,
            faPlane,
            faBriefcase,
            faMedal,
            faChevronRight,
            faChevronLeft,
            faFileInvoiceDollar,
            faHandsUsd)


const store = createStore(combinedReducer, applyMiddleware(thunkMiddleware))
//const store = createStore(
//  combinedReducer, compose(applyMiddleware(thunkMiddleware),
//  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
// )

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Provider store={store}>
      <CookiesProvider>
        <Router>
          <Route path="/" component={PublicApp}></Route>
        </Router>
      </CookiesProvider>
    </Provider>,
    document.body.appendChild(document.createElement('div')),
  )
})
