import React, { useState } from 'react'
import  styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedService  } from './Actions/service'
import { services } from './../Helpers/serviceList'
import { scrollToElement } from './../Helpers/scroller'

const NavList = styled.div`
  color: ${ props => props.theme.colors.matisse };
  margin-top: 20px;

  ul {
    list-style-type: none;
    padding: 0px;

    li {
      padding: 10px 0px;
      border-bottom: 1px solid #eee;

      .sub-menu {
        margin-left: 10px;
        li {
          border-bottom: none;
        }
      }
    }
  }
`

const LinkTitle = styled.span`
  font-family: "League Spartan";
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 2rem;
  text-shadow: 0 2px 4px 0 #FFFFFF;
  text-transform: uppercase;
  cursor: pointer;
`

const VerticalNav = (props) => {
  const banner = useSelector(store => store.banner)
  const dispatch = useDispatch()

  const checkMenuClose = (closeMenuCallback, actionCallback) => {
    actionCallback()
    console.log("Adrian there")
    console.log(props)
    if (props.closeMenuCallback != null) {
      console.log("Adrian everywhere")
      closeMenuCallback()
    }
  }

  const serviceList =  services.map( service => <li key={service.key}>
    <LinkTitle onClick={ () => checkMenuClose(props.closeMenuCallback,() => dispatch(setSelectedService(service.title.replace(/ /g,'')))) }>
      {service.title}
    </LinkTitle>
  </li>)

  const [expand, setExpand] = useState(false)

  const toggleExpand = () => {
    setExpand(!expand)
  }

  return(
    <NavList>
      <ul>
        <li><LinkTitle onClick={ () => { checkMenuClose(props.closeMenuCallback, () => { scrollToElement('about-us', banner.show)}) } }>About Us</LinkTitle></li>
        <li onClick={ () => toggleExpand() }>
          <LinkTitle>Services <FontAwesomeIcon icon={expand ? 'caret-down' : 'caret-right'} /></LinkTitle>
          <ul className="sub-menu">
            { expand && serviceList }
          </ul>
        </li>
        <li><LinkTitle onClick={ () => { checkMenuClose(props.closeMenuCallback, () => { scrollToElement('payment-options', banner.show)}) } }>Payment Options</LinkTitle></li>
        <li><LinkTitle onClick={ () => { checkMenuClose(props.closeMenuCallback, () => { scrollToElement('contact-us', banner.show)}) } }>Contact Us</LinkTitle></li>
        <li><LinkTitle onClick={ () => { window.open('/covid-information.pdf', '_blank')} }>Covid-19 Information</LinkTitle></li>
      </ul>
    </NavList>
  )
}

export default VerticalNav
