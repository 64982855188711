import React from 'react'
import  styled from 'styled-components'

export const Button = styled.div.attrs(props => ({
  className: 'btn'
}))`
  box-shadow: 0 9px 20px 0 rgba(101,145,204,0.24);
  padding: 10px 40px 7px 40px;
  border-radius: 27.5px;
  font-family: "League Spartan";
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.125rem;
  line-height: 1.125rem;
  text-align: center;
  text-transform: uppercase; 
`

export const PrimaryButton = styled(Button)`
  background-color: ${ props => props.theme.colors.matisse };
  border: 1px solid ${ props => props.theme.colors.matisse };
  color: ${ props => props.theme.colors.baseLight };
`

export const TertiaryLightButton = styled(Button)`
  background-color: ${ props => props.theme.colors.baseLight };
  border: 1px solid ${ props => props.theme.colors.baseLight };
  color: ${ props => props.theme.colors.baseDark };
`

export const TertiaryDarkButton = styled(Button)`
  background-color: ${ props => props.theme.colors.baseDark };
  border: 1px solid ${ props => props.theme.colors.baseDark };
  color: ${ props => props.theme.colors.baseLight };
`