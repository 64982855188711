import React, { Fragment, useState } from 'react'
import  styled from 'styled-components'
import { StaffNameSmall,
         StaffTitleSmall,
         CredentialsWrapper,
         Credentials,
         ReadMoreButton,
         WriteupContentFull
       } from './../AboutUs'

import HygienistPhoto from './../../../assets/images/hygienist.jpg'

const Photo = styled.img`
  width: 100%;
  border-radius: 5px;
`

const StyledRow = styled.div.attrs(props => ({
  className: "row" 
}))`
  margin-top: 50px;
`

const StyledPhotoDiv = styled.div.attrs(props => ({
  className: "col-6 col-lg-4"
}))`
  @media (min-width: 992px) {
    text-align: right;

    > img {
      width: 50% !important;
    }
  }
`

const Emeliza = (props) => {
  const [expandMobile, setExpandMobile] = useState(false)

  const toggleExpandMobile = () => {
    setExpandMobile(!expandMobile)
  }

  const writeupExpand = <span> She has been working in the dental field since 2005 and keeps up-to-date with the latest oral care trends and techniques 
    by regularly attending conferences and training workshops. Emeliza enjoys sharing her passions for experiencing 
    different cultures, food and shopping with friends and family, especially her son. She is also fond of scrapbooking and creating photobooks, 
    and likes to watch K-dramas in her free time.
  </span>

  const credentials = <CredentialsWrapper>
    <Credentials icon={['fas', 'medal']} content="Completed her Certification in Dental Assisting at Vancouver Community College in 2005"/>
    <Credentials icon={['fas', 'medal']} content="Completed the Registered Dental Hygiene Program at Vancouver Community College in 2014"/>
    <Credentials icon={['fas', 'plane']} content="Moved from the Philippines to Canada in 1994"/>
    <Credentials icon={['fas', 'comments']} content="Fluent in English and Tagalog"/>
  </CredentialsWrapper>

  const employeeDetails = <Fragment>
    <StaffNameSmall>Emeliza Cabana</StaffNameSmall>
    <StaffTitleSmall>Registered Dental Hygienist</StaffTitleSmall>
  </Fragment>

  return(<StyledRow>
    <div className="d-block d-lg-none col-12">
      { employeeDetails }
    </div>
    <StyledPhotoDiv>
      <Photo src={ HygienistPhoto } alt="Emeliza Cabana"/>
    </StyledPhotoDiv>
    <div className="col-6 col-lg-8">
      <div className="d-lg-block d-none">
        { employeeDetails }
      </div>
      <div className="d-none d-lg-block">
        <div className="row">
          <WriteupContentFull>
            Known by our patients for her warmth and gentle hands, Emeliza is our friendly and very capable Dental Hygienist.
            She has been working in the dental field since 2005 and keeps up-to-date with the latest oral care trends and techniques 
            by regularly attending conferences and training workshops. Emeliza enjoys sharing her passions for experiencing 
            different cultures, food and shopping with friends and family, especially her son. She is also fond of scrapbooking and creating photobooks, 
            and likes to watch K-dramas in her free time.
          </WriteupContentFull>
        </div>
      </div>
      <div className="d-lg-none d-block">
        <div className="row">
          <WriteupContentFull>
            Known by our patients for her warmth and gentle hands, Emeliza is our friendly and very capable Dental Hygienist.

            { expandMobile && writeupExpand }

            <ReadMoreButton className="d-block d-lg-none" onClick={() => toggleExpandMobile() }>Read { expandMobile ? 'Less' : 'More' }</ReadMoreButton>
          </WriteupContentFull>
        </div>
      </div>
      <div className="d-none d-lg-block">
        { credentials }      
      </div>
    </div>
    <div className="d-lg-none d-block col-12">
      { credentials }
    </div>
  </StyledRow>)
}

export default Emeliza
