import React from 'react'
import  styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ArrowContainer = styled.button`
  position: absolute;
  font-size: 1.5rem;
  top: 50%;
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: none;
  user-select: none;
  z-index: 500;
  background: ${ props => props.theme.colors.matisse };
  color: #fff;
  border-radius: 50%;

  &:hover {
    background: ${ props => props.theme.colors.matisse } !important; 
  }

  &:active {
    border-style: inset;
  }

  &:focus {
    outline: none;
    background: ${ props => props.theme.colors.matisse };
  }
`

const StyledPrevArrow = styled(ArrowContainer)`
  left: -25px;
`

const StyledNextArrow = styled(ArrowContainer)`
  right: -25px;
`

export const PrevArrow = (props) => {
  return(<StyledPrevArrow onClick={props.onClick}><FontAwesomeIcon icon={['fal', 'chevron-left']} /></StyledPrevArrow>)
}

export const NextArrow = (props) => {
  return(<StyledNextArrow onClick={props.onClick}><FontAwesomeIcon icon={['fal', 'chevron-right']} /></StyledNextArrow>)
}