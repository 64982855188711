import React, { useState } from 'react'
import  styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../assets/images/logo.png'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedService  } from './Actions/service'
import { services } from './../Helpers/serviceList'
import { scrollToElement } from './../Helpers/scroller'
import VerticalNav from './VerticalNav'

const StyledFooter = styled.div.attrs(props => ({
  className: "container d-flex flex-column"
}))`
  padding: 50px 0px 100px 0px;

  @media (min-width: 992px) {
    padding: 0px 0px 100px 0px;
    margin-top: -20px;
  }
`

const SocialMediaLinks = styled.div`
  padding: 10px 0px;
  color: ${ props => props.theme.colors.matisse };
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
`

const LegalLinks = styled.div`
  padding: 20px 0px;
`

const Copyright = styled.div`
  color: #4A4A4A;
  font-family: Poppins;
  font-size: 0.875rem;
  letter-spacing: 0.25px;
  line-height: 1.25rem;
`

const FooterMenu = styled.div.attrs(props => ({
  className: "d-flex flex-column"
}))`
  color: ${ props => props.theme.colors.matisse };
`

const LinkTitle = styled.span`
  font-family: "League Spartan";
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 2rem;
  text-shadow: 0 2px 4px 0 #FFFFFF;
  text-transform: uppercase;
`
const FacebookIcon = styled.span`
  font-family: "League Spartan";
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 2rem;
  text-shadow: 0 2px 4px 0 #FFFFFF;
  text-transform: uppercase;
`

const FacebookTitle = styled.span`
  font-family: "League Spartan";
  font-size: 1rem;
  font-weight: bold;
  line-height: 2rem;
  text-shadow: 0 2px 4px 0 #FFFFFF;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: auto;
`

const PseudoButton = styled.div`
  color: white;
  background-color: ${ props => props.theme.colors.matisse };
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px 0px 5px;
  border-radius: 25px;
  box-shadow: 0 9px 20px 0 rgba(101,145,204,0.24);
`

const Footer = (props) => {
  const dispatch = useDispatch()

  const serviceList =  services.map( service => <li key={service.key}>
    <LinkTitle onClick={ () => dispatch(setSelectedService(service.title.replace(/ /g,''))) }>
      {service.title}
    </LinkTitle>
  </li>)

  const [expand, setExpand] = useState(false)

  const toggleExpand = () => {
    setExpand(!expand)
  }

  return(<StyledFooter>
      <img src={logo} width={136} height={51} alt="Citident Oral Care Centre Logo"/>
      <FooterMenu>
        <VerticalNav />
      </FooterMenu>
      <SocialMediaLinks>
        <div><LinkTitle>Follow us on Facebook</LinkTitle></div>
        <PseudoButton onClick={ () => window.location.href="https://www.facebook.com/citidentocc" }><FacebookIcon><FontAwesomeIcon icon={['fab', 'facebook']} /></FacebookIcon><FacebookTitle>Citident Oral Care Centre</FacebookTitle></PseudoButton>
      </SocialMediaLinks>
      <Copyright>
        &#169; Citident Oral Care 2020.<br />
        All Rights Reserved. Created by <a href="https://techtank.ca">TechTank Canada, Inc.</a>
      </Copyright>
    </StyledFooter>)
}

export default Footer
