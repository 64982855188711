import React  from 'react'
import  styled from 'styled-components'

const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;
`

const CImage = styled.img`
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`

const CaptionContainer = styled.div`
  position: absolute;
  top: 85%;
  height: 42px !important;
  z-index: 100;
  width: calc(100% - 20px);
  text-align: right;
`

const Caption = styled.span`
  opacity: .83;
  background-color: #13789C;
  color: white;
  padding: 15px 50px;
  font-family: Poppins;
`

const CarouselCaption = (caption) => {
  return <CaptionContainer>
    <Caption>{ caption }</Caption>
  </CaptionContainer>
}

const CarouselPhoto = (props) => {
  return(<CarouselItem>
    <CImage src={ props.photo } />
    { props.caption && CarouselCaption(props.caption) }
  </CarouselItem>)
}

export default CarouselPhoto
