import endodontics from './../../assets/images/services/endodontics.jpg'
import oralSurgery from './../../assets/images/services/oral-surgery.jpg'
import orthodontics from './../../assets/images/services/orthodontics.jpg'
import pediatricDentistry from './../../assets/images/services/pediatric-dentistry.jpg'
import preventiveCare from './../../assets/images/services/preventive-care.jpg'
import prosthodontics from './../../assets/images/services/prosthodontics.jpg'
import restorativeDentistry from './../../assets/images/services/restorative-dentistry.jpg'

export const services = [
  {
    key: 1,
    photo: preventiveCare,
    title: 'Preventive Care',
    description: 'We care a lot about your overall oral health! That’s why we’ll do our best to keep it in tip-top shape with our cleaning and hygiene services, oral and recall exams and diagnostics like panoramic, intraoral x-rays, and intraoral cameras.'
  },
  {
    key: 2,
    photo: oralSurgery,
    title: 'Oral Surgery',
    description: 'We know the pain and hassles of having tooth problems, so we’ll do tooth extractions and other oral surgery procedures only when necessary. And if it is needed, we’ll exert effort to make it as pleasant and pain-free as possible.'
  },
  {
    key: 3,
    photo: endodontics,
    title: 'Endodontics',
    description: 'Sometimes we have to get to the root of the problem. With our root canal treatments, we will remove infections inside a tooth. This dental procedure also helps protect the tooth from future infections.'
  },
  {
    key: 4,
    photo: restorativeDentistry,
    title: 'Restorative Dentistry',
    description: 'Bring back your smile with our restorative procedures like tooth capping and tooth filling. We’ll also make sure to treat cavities when we see them.'
  },
  {
    key: 5,
    photo: orthodontics,
    title: 'Orthodontics',
    description: 'We’d love to help you smile with confidence! We offer braces, aligners and other appliances to prevent and correct malposed teeth and help correct overbites and underbites.'
  },
  {
    key: 6,
    photo: prosthodontics,
    title: 'Prosthodontics',
    description: 'We’ll give you more reasons to smile, because we’ll help restore missing teeth either by fixed (i.e. crowns and bridges) or removable dentures be it maintenance, cleaning or replacement. We also apply crowns, bridges and removable dentures.'
  },
  {
    key: 7,
    photo: pediatricDentistry,
    title: 'Pediatric Dentistry',
    description: 'We love meeting and teaching children how to take care of their gums and teeth! We’ll help your kids develop good oral habits early, so we can prevent problems later on. We offer preventive and restorative dentistry for kids as well!'
  }
]