import React, { useEffect, Fragment } from 'react'
import  styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSTransition } from 'react-transition-group'
import Scroll from 'react-scroll'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedService } from './Actions/service'
import { scrollToElement } from './../Helpers/scroller'

const StyledServiceCard = styled.div`
  background-color: ${ props => props.theme.colors.baseLight };
  color: ${ props => props.theme.colors.baseDark };
  margin-top: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 #7F9AB8;
  overflow: hidden;
  transition: height 200 ease;

  @media (min-width: 992px) {
    > .selected {
      position: absolute;
      width: 255px;
      background: white;
      border-radius: 5px;
      box-shadow: 0 0 10px 0 #7F9AB8;
      z-index: 20;
    }

    > .normal {
      position: relative;
    }
  }
`

const ServicePhoto = styled.div`
  background: #EEE;
  height: 200px;
  border-radius: ${ props => props.expand ? "0px" : "0px 0px 5px 5px"};
  overflow: hidden;
  z-index: 20;

  
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: ${ props => props.theme.colors.matisse };
  font-size: 1.125rem;
  font-weight: 700;
  z-index: 100;
`

const Title = styled.h2`
  font-family: ${ props => props.theme.fonts.body };
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.375rem;
  text-transform: uppercase;
  margin-bottom: 0px;
`

const Description = styled.div`
  padding: 30px;
  color: ${ props => props.theme.colors.primaryDark };
  font-family: ${ props => props.theme.fonts.body };
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;
`

const CloseButton = styled.div.attrs(props => ({
  className: "d-block d-lg-none"
}))`
  margin: 10px 10px 30px;
  text-align: right;
  text-transform: uppercase;
  color: ${ props => props.theme.colors.matisse };
  font-family: ${ props => props.theme.fonts.body };
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.375rem;
`

const Content = styled.div`
  &.test-enter {
    opacity: 0;
    transition: opacity 200ms ease-in;
  }

  &.test-enter-active {
    opacity: 1;
  }

  &.test-exit {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  &.test-exit-active {
    opacity: 0;
  }

  @media (min-width: 992px) {
    display: block;

    &.test-exit-active {
      position: absolute;
      background: white;
      width: 255px;
    }
  }
`

const FadeCover = styled.div`
  display: none;

  @media (min-width: 992px) {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    pointer-events: none;
    opacity: 0.8;

    &.fade-cover-enter {
      opacity: 0;
      transition: opacity 200ms;
    }

    &.fade-cover-enter-active {
      opacity: 0.8;
    }

    &.fade-cover-exit {
      opacity: 0.8;
      transition: opacity 200ms;
    }

    &.fade-cover-exit-active {
      opacity: 0;
    }
  }
`

//const ContentWrapper = styled.div`
//  transition: height 5000ms ease-in;
//`
const Element = Scroll.Element

const ServiceCard = (props) => {
  const serviceState = useSelector(store => store.service)
  const banner = useSelector(store => store.banner)

  const dispatch = useDispatch()

  const serviceName = props.title.replace(/ /g,'')

  const expand = serviceState.selectedService == serviceName

  const fade = serviceState.selectedService != null && !expand

  const setServiceState = () => {
    if (expand) {
      dispatch(setSelectedService(null))
    } else {
      if (serviceState.selectedService != null) {
        dispatch(setSelectedService(null))
        setTimeout(() => { dispatch(setSelectedService(serviceName)) }, 250)
      } else {
        dispatch(setSelectedService(serviceName))
      }
    }
  }

  const resetServiceState = () => {
    dispatch(setSelectedService(null))
  }

  useEffect(() => {
    if (expand) {
      scrollToElement(serviceName, banner.show)
    }
  }, [expand]);

  return(<Element name={ serviceName }>
    <StyledServiceCard>
      <div className={expand ? 'selected' : 'normal' }>
        <CSSTransition in={fade} timeout={100} unmountOnExit classNames="fade-cover">
          <FadeCover /> 
        </CSSTransition>
        <Header onClick={() => setServiceState() }>
          <Title className="d-block">{ props.title }</Title>
          <FontAwesomeIcon icon={expand ? 'caret-down' : 'caret-right'} />
        </Header>
        <ServicePhoto expand={expand}>
          { props.children }
        </ServicePhoto>
        <CSSTransition in={expand} timeout={200} unmountOnExit classNames="test">
          <Content>
            <Description>{ props.description }</Description>
            <CloseButton onClick={ () => resetServiceState()  }><FontAwesomeIcon icon={['fal', 'times']} /> close</CloseButton>
          </Content>
        </CSSTransition>
      </div>
    </StyledServiceCard>
  </Element>)
}

export default ServiceCard
