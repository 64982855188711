import React, { Fragment, useState } from 'react'
import  styled from 'styled-components'
import { StaffName,
         StaffTitle,
         CredentialsWrapper,
         Credentials,
         ReadMoreButton,
         WriteupContent
       } from './../AboutUs'

import DentistPicture from './../../../assets/images/dentist.jpg'

const Photo = styled.img`
  width: 100%;
  border-radius: 5px;
`
 
const Cecile = (props) => {
  const [expandMobile, setExpandMobile] = useState(false)

  const toggleExpandMobile = () => {
    setExpandMobile(!expandMobile)
  }
  
  const credentials = <CredentialsWrapper>
    <Credentials icon={['fas', 'medal']} content="Received Doctor of Dental Medicine (DDM) degree from the University of the Philippines College of Dentistry in April 1997"/>
    <Credentials icon={['fas', 'medal']} content="Placed 8th in the Philippine Dental Licensure Examination in May 1997"/>
    <Credentials icon={['fas', 'briefcase']} content="12 years of private practice in Manila, Philippines before moving to Vancouver, Canada in 2009"/>
    <Credentials icon={['fas', 'medal']} content="Completed the National Dental Examination Board of Canada certification process in just 9 months."/>
    <Credentials icon={['fas', 'medal']} content="1 of only 34 who successfully completed the Equivalency Process in the first year of its administration in Canada in 2011."/>
    <Credentials icon={['fas', 'comments']} content="Fluent in English, Tagalog and Cebuano"/>
    <Credentials icon={['fas', 'church']} content="Active in church and community activities"/>
    <Credentials icon={['fas', 'plane']} content="Enjoys spending time and travelling with her family"/>
  </CredentialsWrapper>

  const writeupExpand = <span> Dr. Cecile herself is an accomplished and experienced practitioner, trained and up-to-date with the latest best practices, cutting-edge techniques and technology. 
      She is an active member of study clubs and a frequent delegate to local and international dental conferences and workshops. 
      She is passionate about providing her patients the best care possible, by making sure each patient is accommodated with professionalism and warmth. 
      Known for her “gentle touch”, Dr. Cecile is very friendly and very easy to talk to.
  </span>


  const employeeDetails = <Fragment>
    <StaffName>Dr. Cecile Tumusok</StaffName>
    <StaffTitle>Dentist</StaffTitle>
  </Fragment>


  return(<div className="row">
    <div className="d-block d-lg-none col-12">
      { employeeDetails }
    </div>
    <div className="col-6 col-lg-4">
      <Photo src={ DentistPicture } alt="Dr. Cecile Tumusok" />
    </div>
    <div className="col-6 col-lg-8">
      <div className="d-lg-block d-none">
        { employeeDetails }
      </div>
      <div className="d-none d-lg-block">
        <div className="row">
          <WriteupContent>
            Dr. Cecile leads a team of highly-qualified dental professionals, all driven to provide you with quality, personalized oral care. 
            Dr. Cecile herself is an accomplished and experienced practitioner, trained and up-to-date with the latest best practices, cutting-edge techniques and technology. 
            She is an active member of study clubs and a frequent delegate to local and international dental conferences and workshops. 

            <ReadMoreButton className="d-block d-lg-none" onClick={() => toggleExpandMobile() }>Read More</ReadMoreButton>
          </WriteupContent>
          <WriteupContent>
              She is passionate about providing her patients the best care possible, by making sure each patient is accommodated with professionalism and warmth. 
              Known for her “gentle touch”, Dr. Cecile is very friendly and very easy to talk to.
          </WriteupContent>
        </div>
      </div>
      <div className="d-lg-none d-block">
        <div className="row">
          <WriteupContent>
            Dr. Cecile leads a team of highly-qualified dental professionals, all driven to provide you with quality, personalized oral care. 

            { expandMobile && writeupExpand }

            <ReadMoreButton className="d-block d-lg-none" onClick={() => toggleExpandMobile() }>Read { expandMobile ? 'Less' : 'More' }</ReadMoreButton>
          </WriteupContent>
        </div>
      </div>
      <div className="d-none d-lg-block">
        { credentials }
      </div>
    </div>
    <div className="d-lg-none d-block col-12">
      { credentials }
    </div>
  </div>)
}

export default Cecile
