const startingState = {
  show: true
}

const bannerReducer = (state = startingState, action) => {
  switch(action.type) {
    case 'SET_BANNER_VISIBILITY':
      return { 
        ...state,
        show: action.payload
      }
    default:
      return state
  }  
};

export default bannerReducer;