import React, { Fragment, useState } from 'react'
import  styled from 'styled-components'
import { StaffNameSmall,
         StaffTitleSmall,
         CredentialsWrapper,
         Credentials,
       } from './../AboutUs'

import ReceptionistPhoto from './../../../assets/images/receptionist.jpg'
import DentalAssistant1 from './../../../assets/images/assistant_1.jpg'
import DentalAssistant2 from './../../../assets/images/assistant_2.jpg'

const Photo = styled.img`
  width: 100%;
  border-radius: 5px;
`

const StyledRow = styled.div.attrs(props => ({
  className: "row" 
}))`
  margin-top: 50px;
`

const OtherStaff = (props) => {
  const [expandMobile, setExpandMobile] = useState(false)

  const toggleExpandMobile = () => {
    setExpandMobile(!expandMobile)
  }

  const StyledPhoto = styled(Photo)`
    height: 95px;
    width: 77px;
    object-fit: contain;


    @media (min-width: 992px) {
      height: 149px;
      width: 120px;
    }
  `

  return(<StyledRow>
    <div className="col-12 col-lg-4">
      <div className="row">
        <div className="col-3 col-lg-5">
          <StyledPhoto src={ ReceptionistPhoto } alt="Micah Apolonio" />
        </div>
        <div className="col-9 col-lg-7">
          <StaffNameSmall style={ { marginBottom: '0px' } }>Milcah</StaffNameSmall>
          <StaffNameSmall>Apolonio</StaffNameSmall>
          <StaffTitleSmall>Receptionist</StaffTitleSmall>
        </div>
      </div>
    </div>
    <div className="col-12 col-lg-4">
      <div className="row mt-3 mt-lg-0">
        <div className="col-3 col-lg-5">
          <StyledPhoto src={ DentalAssistant1 } alt="Micah Apolonio" />
        </div>
        <div className="col-9 col-lg-7">
          <StaffNameSmall style={ { marginBottom: '0px' } }>Dana</StaffNameSmall>
          <StaffNameSmall>Albania</StaffNameSmall>
          <StaffTitleSmall>Dental Assistant</StaffTitleSmall>
        </div>
      </div>
    </div>
    <div className="col-12 col-lg-4">
      <div className="row mt-3 mt-lg-0">
        <div className="col-3 col-lg-5">
          <StyledPhoto src={ DentalAssistant2 } alt="Micah Apolonio" />
        </div>
        <div className="col-9 col-lg-7">
          <StaffNameSmall style={ { marginBottom: '0px' } }>Shammay</StaffNameSmall>
          <StaffNameSmall>Sabueso</StaffNameSmall>
          <StaffTitleSmall>Dental Assistant</StaffTitleSmall>
        </div>
      </div>
    </div>
  </StyledRow>)
}

export default OtherStaff
