import Scroll from 'react-scroll'

const scroller = Scroll.scroller

export const scrollToElement = (elementName, bannerVisible) => {
  const offsetValue = bannerVisible ? -300 : -150
  scroller.scrollTo(elementName, {
    duration: 200,
    isDynamic: true,
    smooth: true,
    offset: offsetValue
  })
}
