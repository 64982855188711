import menuReducer from './menuReducer'
import serviceReducer from './serviceReducer'
import bannerReducer from './bannerReducer'
import { combineReducers } from 'redux'

const combinedReducers = combineReducers({
  menu: menuReducer,
  service: serviceReducer,
  banner: bannerReducer
});

export default combinedReducers