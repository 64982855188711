import React  from 'react'
import  styled from 'styled-components'
import { SectionTitle } from './Titles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StyledPaymentSection = styled.div.attrs(props => ({
    className: "row"
}))`

  background: #EEE;
`

const PaymentSectionContent = styled.div.attrs(props => ({
    className: "container d-block d-md-flex"
}))`

  background: #EEE;
  padding: 50px 15px;

  @media (min-width: 992px) {
    > div {
      &:first-child {
        padding: 50px 100px 50px 0px;
        margin-top: auto;
      }

      &:not(:first-child) {
        border-left: 2px solid #CCC;
        padding: 30px 50px;
      }

      &:last-child {
          padding-right: 0px;
      }
    }
  }

`

const Title = styled.div`
  color: #4A4A4A;
  font-family: "League Spartan";
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.36px;
  line-height: 1.875rem;
  text-align: center;
  margin-bottom: 10px;

  @media (min-width: 992px) {
    text-align: left;
    font-size: 1.5rem;
    min-height: 4.375rem;
  }
`

const Content = styled.div`
  color: #4A4A4A;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 19px;
  text-align: center;

  @media (min-width: 992px) {
    text-align: left;
  }
`

const Icon = styled.div`
  color: #4A4A4A;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.125rem;

  @media (min-width: 992px) {
    font-size: 1rem;
    text-align: left;
  }
`

const PaymentOption = styled.div`
  margin-bottom: 70px;

  @media (min-width: 992px) {
      margin-bottom: 0px;
  }
`

const MiddleCreditCardWrapper = styled.span`
  margin: 0px 25px;

  @media (min-width: 992px) {
      margin: 0px 5px;
  }
`

const SectionTitleWrapper = styled.div`
  margin-bottom: 50px;
`

const ContactSection = (props) => {
  return<StyledPaymentSection name="payment-options">
      <PaymentSectionContent>
        <SectionTitleWrapper>
            <SectionTitle>Payment Options</SectionTitle>
        </SectionTitleWrapper>
        <PaymentOption>
            <Icon><FontAwesomeIcon icon={['fal', 'file-invoice-dollar']} size="2x" /></Icon>
            <Title>Direct Billing</Title>
            <Content>We process direct billing to insurance companies for those with extended dental benefits</Content>
        </PaymentOption>
        <PaymentOption>
            <Icon><FontAwesomeIcon icon={['fas', 'hands-usd']} size="2x" /></Icon>
            <Title>Flexible Payment Arrangements</Title>
            <Content>We also offer flexible payment arrangements for those without insurance coverage</Content>
        </PaymentOption>
        <PaymentOption>
            <Icon><FontAwesomeIcon icon={['fab', 'cc-mastercard']} size="2x" /><MiddleCreditCardWrapper><FontAwesomeIcon icon={['fab', 'cc-visa']} size="2x" /></MiddleCreditCardWrapper><FontAwesomeIcon icon={['fab', 'cc-amex']} size="2x" /></Icon>
            <Title>Debit & Credit Cards</Title>
            <Content>We accept Interac debit, Mastercard, Visa, and American Express</Content>
        </PaymentOption>
      </PaymentSectionContent>
    </StyledPaymentSection>
}

export default ContactSection
